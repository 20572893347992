import "./styles.scss"
import React from "react"
import TextField from "@material-ui/core/TextField"
// import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import PhoneNumber from "./phoneNumber"
import PaperDropzone from "./../PaperDropzone"
// import InputLabel from "@material-ui/core/InputLabel"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
// import PriceInput from "./../priceInput"
// import Select from "@material-ui/core/Select"
// import MenuItem from "@material-ui/core/MenuItem"
// import ReCAPTCHA from "react-google-recaptcha"
import isPhoneNumberValid from "../isPhoneNumberValid"
import AppContext from "./../appContext"
// import EngineTypeInput from "./engineTypeInput.js"
import ColorButton from "./../colorButton"
import ColorCheckbox from "./../colorCheckbox"
import { take } from "lodash/array"

const spacing = 2
const MAX_FILES_COUNT = 15

const SubmitForm = ({ onSubmit }) => {
  const appDispatcher = React.useContext(AppContext)

  const [phone, setPhone] = React.useState(PhoneNumber.defaultValue)
  const [transmission, setTransmission] = React.useState("Не указано")
  const [amount, setAmount] = React.useState()
  const [brand, setBrand] = React.useState()
  const [model, setModel] = React.useState()
  const [year, setYear] = React.useState()
  const [isWorking, setIsWorking] = React.useState(true)
  const [moreInfo, setMoreInfo] = React.useState()
  const [files, setUploadedFiles] = React.useState([])
  // const [captcha, setCaptcha] = React.useState()
  const [contactName, setContactName] = React.useState()
  const [engineType, setEngineType] = React.useState([])
  const [volume, setVolume] = React.useState()

  // const captchaRef = React.useRef()

  const handleUpdate = React.useCallback(
    func => event => func(event.target.value),
    []
  )

  const handleSetIsWorking = React.useCallback(
    event => setIsWorking(event.target.checked),
    [setIsWorking]
  )

  const handleUploadFiles = React.useCallback(
    uploadedFiles => {
      let files = [...uploadedFiles]
      if (files.length > MAX_FILES_COUNT) {
        files = take(files, MAX_FILES_COUNT)
        appDispatcher({
          type: "open",
          severity: "warning",
          message: `Не добавляйте более ${MAX_FILES_COUNT} фото.`,
        })
      }
      setUploadedFiles(files)
    },
    [setUploadedFiles]
  )

  const clearInputForm = React.useCallback(() => {
    setPhone(PhoneNumber.defaultValue)
    setTransmission("Не указано")
    setAmount("")
    setBrand("")
    setModel("")
    setYear("")
    setIsWorking(true)
    setMoreInfo("")
    setUploadedFiles([])
    // setCaptcha("")
    setContactName("")
    setEngineType([])
    setVolume("")
  }, [
    setPhone,
    setTransmission,
    setAmount,
    setBrand,
    setModel,
    setYear,
    setIsWorking,
    setMoreInfo,
    setUploadedFiles,
    // setCaptcha,
    setContactName,
    setEngineType,
    setVolume,
  ])

  const handleSubmit = React.useCallback(() => {
    // if (!captcha) {
    //   appDispatcher({
    //     type: "open",
    //     severity: "error",
    //     message: "Пожалуйста, пройдите капчу (подтверидите что вы не робот)!",
    //   })
    //   return
    // }
    if (!isPhoneNumberValid(phone)) {
      appDispatcher({
        type: "open",
        severity: "error",
        message: "Пожалуйста, введите свой номер телефона!",
      })
      return
    }
    // captchaRef.current.reset()
    onSubmit({
      phone: phone
        .split("")
        .filter(el => el !== " ")
        .join(""),
      transmission,
      amount,
      brand,
      model,
      year,
      isWorking,
      moreInfo,
      files: files.map(({ dataUrlStr }) => dataUrlStr),
      // captcha,
      contactName,
      engineType: engineType.filter(type => !!type).join(", "),
      volume,
    }).then(clearInputForm)
  }, [
    // captchaRef,
    onSubmit,

    phone,
    transmission,
    amount,
    brand,
    model,
    year,
    isWorking,
    moreInfo,
    files,
    // captcha,
    contactName,
    volume,

    appDispatcher,
    engineType,
    clearInputForm,
  ])

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Контактные данные
      </Typography>
      <Grid container spacing={spacing}>
        <Grid item xs={12} sm={6}>
          <TextField
            value={brand}
            onChange={handleUpdate(setBrand)}
            fullWidth
            required
            label="Марка"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={model}
            onChange={handleUpdate(setModel)}
            fullWidth
            required
            label="Модель"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={year}
            onChange={handleUpdate(setYear)}
            fullWidth
            required
            type="number"
            label="Год выпуска"
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <TextField
            value={contactName}
            onChange={handleUpdate(setContactName)}
            fullWidth
            required
            label="Ваше имя"
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <PhoneNumber
            fullWidth
            required
            label="Номер для связи"
            value={phone}
            onChange={setPhone}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <TextField
            value={amount}
            onChange={handleUpdate(setAmount)}
            fullWidth
            required
            label="Оценка авто $"
            InputProps={{
              inputComponent: PriceInput,
            }}
          />
        </Grid> */}
        {/* <Grid item xs={6}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel
              htmlFor="transmission-select-id"
              id="transmission-label-id"
              margin="dense"
              fullWidth
            >
              Коробка передач
            </InputLabel>
            <Select
              labelId="transmission-label-id"
              id="transmission-select-id"
              value={transmission}
              onChange={handleUpdate(setTransmission)}
              fullWidth
            >
              <MenuItem value={"Не указано"}>Не указано</MenuItem>
              <MenuItem value={"АКПП"}>АКПП</MenuItem>
              <MenuItem value={"МКПП"}>МКПП</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item sm={6} xs={12}>
          <FormControlLabel
            required
            control={
              <ColorCheckbox
                checked={isWorking}
                onChange={handleSetIsWorking}
              />
            }
            label="Машина на ходу"
          />
        </Grid>
        {/* <Grid item sm={6} xs={12}>
          <TextField
            value={volume}
            onChange={handleUpdate(setVolume)}
            fullWidth
            required
            type="number"
            label="Объем двигателя, см³"
          />
        </Grid> */}
        {/* <Grid item xs={12}>
          <EngineTypeInput value={engineType} onChange={setEngineType} />
        </Grid> */}
        <Grid item xs={12}>
          <TextField
            value={moreInfo}
            onChange={handleUpdate(setMoreInfo)}
            fullWidth
            label="Дополнительно"
            multiline
            rows={6}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <PaperDropzone
            files={files}
            onFilesChange={handleUploadFiles}
            maxFilesCount={MAX_FILES_COUNT}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <Grid container direction="row" justify="center" alignItems="center">
            <ReCAPTCHA
              ref={captchaRef}
              sitekey="6LeYF-8UAAAAABHssxRmmvvzBl9cmROECk5KLL5_"
              onChange={setCaptcha}
            ></ReCAPTCHA>
          </Grid>
        </Grid> */}
        <Grid item xs={12}>
          <ColorButton
            fullWidth
            variant="contained"
            color="default"
            type="submit"
            onClick={handleSubmit}
          >
            Узнать стоимость
          </ColorButton>
        </Grid>
      </Grid>
    </>
  )
}

SubmitForm.displayName = "SubmitForm"
export default SubmitForm
