import React from "react"
import Button from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core/styles"

import { colorMain, colorMainDark } from "./const/color"

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(colorMain),
    backgroundColor: colorMain,
    "&:hover": {
      backgroundColor: colorMainDark,
    },
  },
}))(Button)

ColorButton.displayName = "ColorButton"

export default ColorButton
