import "./styles.scss"

import React from "react"
import { useDropzone } from "react-dropzone"
import RootRef from "@material-ui/core/RootRef"
import Typography from "@material-ui/core/Typography"

import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import DeleteIcon from "@material-ui/icons/Delete"
import IconButton from "@material-ui/core/IconButton"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import Avatar from "@material-ui/core/Avatar"
import Grid from "@material-ui/core/Grid"
import imageCompression from "browser-image-compression"
import CircularProgress from "@material-ui/core/CircularProgress"
import AppContext from "./../appContext"

const InputFileAccept = "image/*"
const options = {
  maxSizeMB: 1,
  useWebWorker: true,
}

const getId = () => Math.floor(Math.random() * 100000)

const adoptFileImage = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onabort = () => reject("file reading was aborted")
    reader.onerror = () => reject("file reading has failed")
    reader.onload = () => {
      resolve({
        name: file.name,
        id: getId(),
        dataUrlStr: reader.result,
      })
    }
    reader.readAsDataURL(file)
  })

const compressImage = f => imageCompression(f, options)

export default function PaperDropzone({ onFilesChange, files, maxFilesCount }) {
  const [images, setImages] = React.useState([])
  const [isZoneLoading, setIsZoneLoading] = React.useState(false)
  const appDispatcher = React.useContext(AppContext)

  const onDrop = React.useCallback(
    acceptedFiles => {
      const load = async () => {
        const newFiles = []
        setIsZoneLoading(true)
        for (let i = 0; i < acceptedFiles.length; i++) {
          const current = acceptedFiles[i]
          const newImg = await adoptFileImage(await compressImage(current))
          setImages(images => [...images, newImg])
          newFiles.push(newImg)
        }
        const combinedFiles = [...files, ...newFiles]
        onFilesChange(combinedFiles)
        setIsZoneLoading(false)
        appDispatcher({
          type: "open",
          severity: "success",
          message: "Изображения готовы к загрузке",
        })
      }
      load()
    },
    [files, onFilesChange]
  )

  React.useEffect(() => {
    if (files) {
      setImages(files)
    }
  }, [files])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const { ref, ...rootProps } = getRootProps()

  const removeFile = React.useCallback(
    id => {
      const newFiles = files.filter(f => f.id !== id)
      onFilesChange(newFiles)
    },
    [onFilesChange, files]
  )

  return (
    <RootRef rootRef={ref}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <Grid container direction="row" justify="center" alignItems="center">
            {isZoneLoading && (
              <Grid
                direction="column"
                container
                justify="center"
                style={{ textAlign: "center" }}
              >
                <Grid item>Идет обработка изображений</Grid>
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            )}
            {images && (
              <Grid item xs={12}>
                <List>
                  {images.map(({ id, name, dataUrlStr }) => (
                    <ListItem key={id}>
                      <ListItemAvatar>
                        <Avatar src={dataUrlStr}></Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={<Typography noWrap={true}>{name}</Typography>}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => removeFile(id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}
            <Grid item xs={12}>
              <div {...rootProps}>
                <input {...getInputProps()} accept={InputFileAccept} />
                {/* <div className="dropzone">
                  <CloudUploadIcon />
                  <p>Перетащите фото</p>
                </div> */}
                <Button
                  variant="contained"
                  color="default"
                  fullWidth
                  disabled={files.length >= maxFilesCount}
                  startIcon={<CloudUploadIcon />}
                >
                  {files.length ? "Загрузить еще фото" : "Загрузить фото"}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RootRef>
  )
}
