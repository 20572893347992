import React from "react"
import MaskedInput from "react-text-mask"
import TextField from "@material-ui/core/TextField"
import isPhoneNumberValid from "../isPhoneNumberValid"
import NoSsr from "@material-ui/core/NoSsr"

function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        "+",
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  )
}

export default function PhoneNumber({ value, onChange, label, ...other }) {
  const isPhoneValid = isPhoneNumberValid(value)

  const handleChange = React.useCallback(
    event => onChange(event.target.value),
    [onChange]
  )

  const ref = React.useRef()
  const handleFocus = React.useCallback(() => {
    let cursorPosition = value.length - 1
    const isCharEmpty = val => !val.trim()
    while (cursorPosition > 0) {
      if (isCharEmpty(value[cursorPosition])) {
        cursorPosition--
      } else if (
        (value[cursorPosition] === "-" ||
          value[cursorPosition] === ")" ||
          value[cursorPosition] === "(") &&
        isCharEmpty(value[cursorPosition - 1])
      ) {
        cursorPosition--
      } else {
        break
      }
    }
    if (cursorPosition !== 0 && cursorPosition < value.length) {
      cursorPosition++
    }

    setTimeout(() => {
      ref?.current?.children[1]?.children[0]?.setSelectionRange &&
        ref.current.children[1].children[0].setSelectionRange(
          cursorPosition,
          cursorPosition
        )
    }, 0)
  }, [ref, value])

  return (
    <NoSsr>
      <TextField
        id="formatted-text-mask-input"
        error={!isPhoneValid}
        helperText={isPhoneValid ? "" : "Номер обязателен!"}
        label={label}
        value={value}
        onChange={handleChange}
        ref={ref}
        onFocus={handleFocus}
        InputProps={{ inputComponent: TextMaskCustom }}
        {...other}
      />
    </NoSsr>
  )
}
PhoneNumber.defaultValue = "+(375)"
PhoneNumber.displayName = "PhoneNumber"
